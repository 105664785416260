<template>
  <base-layout-two
    page-title="Room Type"
    page-default-back-link="/room_types"
    end-button-text="Edit"
    :end-button-url="`/room_types/${$route.params.id}/edit`"
  >
    <section class="ion-padding background-white">
      <h1>{{ room_type.name }}</h1>
    </section>

    <div class="padding-8">
      <base-card title="Room Type Details">
        <base-field-display labelText="Room Type">
          <p>{{ room_type.name }}</p>
        </base-field-display>

        <base-field-display labelText="Property">
          <p>{{ room_type.property.name }}</p>
        </base-field-display>

        <base-field-display labelText="Retail Price">
          <p v-if="room_type.price_retail">
            ${{
              room_type.price_retail.toLocaleString("en", {
                minimumFractionDigits: 2,
              })
            }}
          </p>
        </base-field-display>

        <base-field-display labelText="Description">
          <p>{{ room_type.description }}</p>
        </base-field-display>
      </base-card>
    </div>
  </base-layout-two>
</template>

<script>
export default {
  data() {
    return {
      room_type: {
        property: {},
      },
    };
  },

  ionViewWillEnter() {
    this.fetchRoomType();
  },

  methods: {
    async fetchRoomType() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/room_types/${this.$route.params.id}`)
        .then((response) => {
          this.room_type = response.data.room_type;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>